var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"privacy-terms"},[_c('div',{staticClass:"content content_top_margin"},[_c('div',{staticClass:"content_inner"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"container_inner default_template_holder clearfix page_container_inner"},[_c('div',{staticClass:"vc_row wpb_row section vc_row-fluid",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"full_section_inner clearfix"},[_c('div',{staticClass:"wpb_column vc_column_container vc_col-sm-12"},[_c('div',{staticClass:"vc_column-inner"},[_c('div',{staticClass:"wpb_wrapper"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"wpb_text_column wpb_content_element"},[_c('div',{staticClass:"wpb_wrapper"},[_vm._m(2),_c('p',[_vm._v(" ")]),_vm._m(3),_c('p',[_vm._v(" ")]),_vm._m(4),_c('p',[_vm._v(" ")]),_vm._m(5),_c('p',[_vm._v(" ")]),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('p',[_vm._v(" ")]),_c('p',[_c('b',[_vm._v("Privacy Policy")]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(" can be viewed by "),_c('router-link',{attrs:{"to":{ name: 'privacy-policy' }}},[_vm._v(" clicking here. ")])],1)])])])])])])])]),_vm._m(10)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wpb_text_column wpb_content_element"},[_c('div',{staticClass:"wpb_wrapper"},[_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v("Terms of Service")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vc_empty_space",staticStyle:{"height":"52px"}},[_c('span',{staticClass:"vc_empty_space_inner"},[_c('span',{staticClass:"empty_space_image"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Welcome to Pbergo.com!")]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(" By using this site and services, users agree to be bound by these terms. The information provided in herein is intended for use as a resource and for educational purposes only ")]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("and does not constitute legal, medical or other professional advice on any subject matter.")]),_vm._v(" "),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("None of our information is a substitute for advice from your physician or other health care provider.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("No Warranty/Limitation of Liability")]),_c('b',[_vm._v(". ")]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Pbergo.com")]),_vm._v(" "),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("makes no warranties of any kind, express or implied. Pbergo.com is provided “as is” without any express or implied warranty of any kind. Pbergo.com does not warrant or assume responsibility for the accuracy or completeness of any information, text, graphics, links, or other items contained within. Pbergo.com shall have no liability with respect to the content therein or any part thereof, including but not limited to errors or omissions contained therein, libel, infringements of rights of publicity, privacy, trademark rights, business interruption, personal injury, loss of privacy, moral rights, or the disclosure of confidential information.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("International Use")]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(".")]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(" Pbergo.com makes no representation that the Content on the Website is appropriate for use in locations outside the United States. Those who choose to access this Website outside the United States do so on their own initiative and are responsible for compliance with local laws.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Changes to Terms")]),_c('b',[_vm._v(". ")]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Pbergo.com reserves the right to modify these Terms at any time, and each such modification will be effective upon posting on the Site. All material modifications will apply prospectively only. To stay informed of any changes, please review the most current version of these Terms posted on the Site. If you do not agree to be bound by these Terms, you must stop using the Site immediately. Pbergo.com assumes no liability or responsibility for your failure to review the current version of these Terms.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("User Indemnity")]),_c('b',[_vm._v(".")]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(" You agree to defend, indemnify, and hold Pbergo.com, its affiliate partners, and their respective affiliates harmless from and against any claims, liabilities, damages, losses and expenses, including reasonable attorney fees and costs, in connection with:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',{staticStyle:{"font-weight":"400"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("your violation of these Terms;")])]),_c('li',{staticStyle:{"font-weight":"400"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("your violation of any third-party right, including any intellectual property right; or")])]),_c('li',{staticStyle:{"font-weight":"400"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("any third-party claim of damage")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("This indemnity obligation will survive your use of the information/resources provided on this site.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Severability.")]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(" If any provision of this Agreement or the application thereof to any person or circumstance shall, to any extent, be invalid or unenforceable, the remainder of this Agreement shall not be affected thereby, and each provision of this Agreement shall remain in effect and be enforceable to the fullest extent permitted by law.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vc_row wpb_row section vc_row-fluid",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"full_section_inner clearfix"},[_c('div',{staticClass:"wpb_column vc_column_container vc_col-sm-12"},[_c('div',{staticClass:"vc_column-inner"},[_c('div',{staticClass:"wpb_wrapper"},[_c('div',{staticClass:"vc_empty_space",staticStyle:{"height":"32px"}},[_c('span',{staticClass:"vc_empty_space_inner"},[_c('span',{staticClass:"empty_space_image"})])])])])])])])
}]

export { render, staticRenderFns }