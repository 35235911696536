<template>
	<div class="privacy-terms">
		<div class="content content_top_margin">
			<div class="content_inner  ">
				<div class="container">
					<div class="container_inner default_template_holder clearfix page_container_inner">
						<div class="vc_row wpb_row section vc_row-fluid " style=' text-align:left;'>
							<div class=" full_section_inner clearfix">
								<div class="wpb_column vc_column_container vc_col-sm-12">
									<div class="vc_column-inner">
										<div class="wpb_wrapper">
											<div class="wpb_text_column wpb_content_element ">
												<div class="wpb_wrapper">
													<h2 style="text-align: center;">Terms of Service</h2>

												</div>
											</div>
											<div class="vc_empty_space" style="height: 52px"><span
													class="vc_empty_space_inner">
													<span class="empty_space_image"></span>
												</span></div>


											<div class="wpb_text_column wpb_content_element ">
												<div class="wpb_wrapper">
													<p><b>Welcome to Pbergo.com!</b><span style="font-weight: 400;"> By
															using this site and services, users agree to be bound by
															these terms. The information provided in herein is intended
															for use as a resource and for educational purposes only
														</span><span style="font-weight: 400;">and does not constitute
															legal, medical or other professional advice on any subject
															matter.</span> <span style="font-weight: 400;">None of our
															information is a substitute for advice from your physician
															or other health care provider.</span></p>
													<p>&nbsp;</p>
													<p><b>No Warranty/Limitation of Liability</b><b>. </b><span
															style="font-weight: 400;">Pbergo.com</span> <span
															style="font-weight: 400;">makes no warranties of any kind,
															express or implied. Pbergo.com is provided “as is” without
															any express or implied warranty of any kind. Pbergo.com does
															not warrant or assume responsibility for the accuracy or
															completeness of any information, text, graphics, links, or
															other items contained within. Pbergo.com shall have no
															liability with respect to the content therein or any part
															thereof, including but not limited to errors or omissions
															contained therein, libel, infringements of rights of
															publicity, privacy, trademark rights, business interruption,
															personal injury, loss of privacy, moral rights, or the
															disclosure of confidential information.</span></p>
													<p>&nbsp;</p>
													<p><b>International Use</b><span
															style="font-weight: 400;">.</span><span
															style="font-weight: 400;"> Pbergo.com makes no
															representation that the Content on the Website is
															appropriate for use in locations outside the United States.
															Those who choose to access this Website outside the United
															States do so on their own initiative and are responsible for
															compliance with local laws.</span></p>
													<p>&nbsp;</p>
													<p><b>Changes to Terms</b><b>. </b><span
															style="font-weight: 400;">Pbergo.com reserves the right to
															modify these Terms at any time, and each such modification
															will be effective upon posting on the Site. All material
															modifications will apply prospectively only. To stay
															informed of any changes, please review the most current
															version of these Terms posted on the Site. If you do not
															agree to be bound by these Terms, you must stop using the
															Site immediately. Pbergo.com assumes no liability or
															responsibility for your failure to review the current
															version of these Terms.</span></p>
													<p>&nbsp;</p>
													<p><b>User Indemnity</b><b>.</b><span style="font-weight: 400;"> You
															agree to defend, indemnify, and hold Pbergo.com, its
															affiliate partners, and their respective affiliates harmless
															from and against any claims, liabilities, damages, losses
															and expenses, including reasonable attorney fees and costs,
															in connection with:</span></p>
													<ul>
														<li style="font-weight: 400;"><span
																style="font-weight: 400;">your violation of these
																Terms;</span></li>
														<li style="font-weight: 400;"><span
																style="font-weight: 400;">your violation of any
																third-party right, including any intellectual property
																right; or</span></li>
														<li style="font-weight: 400;"><span
																style="font-weight: 400;">any third-party claim of
																damage</span></li>
													</ul>
													<p><span style="font-weight: 400;">This indemnity obligation will
															survive your use of the information/resources provided on
															this site.</span></p>
													<p><b>Severability.</b><span style="font-weight: 400;"> If any
															provision of this Agreement or the application thereof to
															any person or circumstance shall, to any extent, be invalid
															or unenforceable, the remainder of this Agreement shall not
															be affected thereby, and each provision of this Agreement
															shall remain in effect and be enforceable to the fullest
															extent permitted by law.</span></p>
													<p>&nbsp;</p>
													<p><b>Privacy Policy</b><span style="font-weight: 400;"> can be
															viewed by  <router-link :to="{ name: 'privacy-policy' }">
															clicking here. </router-link>
															</span></p>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="vc_row wpb_row section vc_row-fluid " style=' text-align:left;'>
							<div class=" full_section_inner clearfix">
								<div class="wpb_column vc_column_container vc_col-sm-12">
									<div class="vc_column-inner">
										<div class="wpb_wrapper">
											<div class="vc_empty_space" style="height: 32px"><span
													class="vc_empty_space_inner">
													<span class="empty_space_image"></span>
												</span></div>

										</div>
									</div>
								</div>
							</div>
						</div>


					</div>
				</div>
			

			</div>
		</div>

	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'terms-of-serivce',
	}
</script>